<template>
    <div>
        <v-container
            id="users"
            fluid
            class="fill-height flex-column flex-nowrap align-start"
        >
            <v-container fluid class="pt-5 px-5 mb-10">
                <v-row justify="space-between">
                    <v-col md="6" cols="12" class="d-flex">
                        <v-toolbar-title
                            class="headline font-weight-bold accent--text"
                            >Profile Management</v-toolbar-title
                        >
                        <v-select
                            v-model="selectedMinistryPage"
                            background-color="transparent"
                            item-text="text"
                            item-value="value"
                            :menu-props="{ top: false, offsetY: true }"
                            class="ma-0 pa-0 ml-10 ministry-pages"
                            :items="profileItems"
                            @change="changeView"
                            color="primaryTextColor"
                            :style="`max-width: 200px; font-size: 20px; font-weight: 800;`"
                        ></v-select>
                    </v-col>
                    <v-col md="auto" cols="12" class="d-inline-flex flex-row">
                        <v-select
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            dense
                            :menu-props="{ top: true, offsetY: true }"
                            placeholder="Sort By"
                            color="secondary"
                            item-value="value"
                            item-text="text"
                            :items="sorts"
                            style="max-width: 11.25rem"
                            clearable
                            @click:clear="table.options.sortBy = null"
                            v-model="table.options.sortBy"
                        ></v-select>
                        <div class="mx-1"></div>
                        <v-select
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            dense
                            placeholder="Show"
                            :menu-props="{ top: true, offsetY: true }"
                            color="secondary"
                            :items="perPageItems"
                            style="max-width: 16rem; color: black !important"
                            clearable
                            @click:clear="table.pagination.perPage = 10"
                            v-model="table.pagination.perPage"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-row justify="space-between">
                    <v-col md="6" lg="auto" cols="12">
                        <v-btn
                            class="border-radius-25 px-5 mx-1"
                            depressed
                            rounded
                            dark
                            color="#2C423D"
                            @click="openUserFormDialog('add')"
                            >Add User</v-btn
                        >
                    </v-col>
                    <v-col md="6" lg="auto" cols="12" class="d-flex flex-row">
                        <v-select
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            dense
                            style="max-width: 11.25rem"
                            color="secondary"
                            :menu-props="{ top: true, offsetY: true }"
                            placeholder="Filter by Membership"
                            item-text="name"
                            item-value="name"
                            :items="memberships"
                            clearable
                            @click:clear="table.options.membership = null"
                            v-model="table.options.membership"
                        ></v-select>
                        <div class="mx-1"></div>
                        <v-select
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            dense
                            style="max-width: 11.25rem"
                            color="secondary"
                            :menu-props="{ offsetY: true }"
                            placeholder="Filter by Milestone"
                            item-text="name"
                            item-value="name"
                            :items="milestones"
                            clearable
                            @click:clear="table.options.milestone = null"
                            v-model="table.options.milestone"
                        ></v-select>
                        <div class="mx-1"></div>
                        <v-text-field
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            dense
                            placeholder="Filter by Creation Date"
                            color="secondary"
                            append-icon="mdi-calendar-outline"
                            style="max-width: 20rem"
                            readonly
                            :value="dateRangeDescription"
                            @click="isDateRangeDialogOpen = true"
                            clearable
                            @click:clear="clearDateRanges"
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
            <v-container
                fluid
                class="flex-grow-1 fill-height align-start justify-center"
            >
                <v-row>
                    <v-col>
                        <v-data-table
                            class="custom-table"
                            :headers="table.headers"
                            :loading="table.loading"
                            :items="table.items"
                            :server-items-length="table.pagination.total"
                            :items-per-page.sync="table.pagination.perPage"
                            :page.sync="table.pagination.page"
                            :footer-props="{
                                'items-per-page-options':
                                    table.pagination.itemsPerPageOptions,
                            }"
                            hide-default-footer
                        >
                            <template v-slot:item.middle_name="{ item }">
                                <span>{{
                                    item.middle_name ? item.middle_name : "--"
                                }}</span>
                            </template>
                            <template v-slot:item.suffix="{ item }">
                                <span>{{
                                    item.suffix ? item.suffix : "--"
                                }}</span>
                            </template>
                            <!--                            <template v-slot:item.milestones="{ item }">-->
                            <!--                                <span>{{-->
                            <!--                                    countMilestones(item.user_milestones)-->
                            <!--                                }}</span>-->
                            <!--                            </template>-->
                            <template v-slot:item.created_at="{ item }">
                                <span>{{ formatDate(item.created_at) }}</span>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <div class="d-flex align-center justify-center">
                                    <v-tooltip bottom>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-btn
                                                icon
                                                depressed
                                                dark
                                                rounded
                                                class="mr-1"
                                                @click="
                                                    openMinistryInformationDialog(
                                                        item
                                                    )
                                                "
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon color="#78756A"
                                                    >mdi-fullscreen</v-icon
                                                >
                                            </v-btn>
                                        </template>
                                        <span>Information</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-btn
                                                icon
                                                depressed
                                                dark
                                                rounded
                                                @click="
                                                    openMilestoneDialog(item)
                                                "
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon color="#78756A"
                                                    >mdi-clipboard-list-outline</v-icon
                                                >
                                            </v-btn>
                                        </template>
                                        <span>Milestone</span>
                                    </v-tooltip>
                                </div>
                            </template>
                        </v-data-table>
                        <Pagination
                            :total-pages="table.pagination.lastPage"
                            :current-page="table.pagination.currentPage"
                            @change="onPaginationChange"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </v-container>

        <user-form-dialog
            :show.sync="userForm.show"
            :action="userForm.action"
            :data="userForm.data"
            @onSuccess="getUsers"
            @close="userForm = { ...userForm, show: false, data: null }"
        ></user-form-dialog>

        <ministry-information-dialog
            :show.sync="userInformationDialog.show"
            selectedProfile="Users"
            selectedMinistryPage="Users"
            :loadedProfile="userInformationDialog.data"
            @close="userInformationDialog.show = false"
            @showUpdateForm="openUserFormDialog('edit')"
            @onSuccess="getUsers"
            v-if="userInformationDialog.show"
        ></ministry-information-dialog>

        <user-milestone-dialog
            :show.sync="userMilestoneDialog.show"
            :data="userMilestoneDialog.data"
            @onSuccess="getUsers"
            @close="userMilestoneDialog.show = false"
            v-if="userMilestoneDialog.show"
        ></user-milestone-dialog>

        <base-date-range-dialog
            :is-open.sync="isDateRangeDialogOpen"
            :start-date.sync="table.options.dateFrom"
            :end-date.sync="table.options.dateTo"
            :selected-date-range.sync="dateRangeDescription"
        ></base-date-range-dialog>
    </div>
</template>

<script>
import MinistryInformationDialog from "@/components/MainView/Ministry/modals/view-modal";
import Pagination from "../../../Core/Pagination.vue";
import { GET_USERS } from "@/store/modules/user";
import BaseDateRangeDialog from "@/components/Base/BaseDateRangeDialog";
import UserFormDialog from "@/components/MainView/Ministry/Users/components/UserFormDialog";
import dateUtility from "@/mixins/date";
import {
    GET_PROFILE_DESIGNATIONS,
    GET_PROFILE_MEMBERSHIPS,
    GET_PROFILE_MILESTONES,
    GET_PROFILE_RELATIONSHIPS,
    GET_PROFILE_ROLES,
} from "@/store/modules/profile";
import UserMilestoneDialog from "@/components/MainView/Ministry/Users/components/UserMilestoneDialog";

export default {
    name: "ministry-users",

    components: {
        UserMilestoneDialog,
        UserFormDialog,
        MinistryInformationDialog,
        Pagination,
        BaseDateRangeDialog,
    },

    data() {
        return {
            table: {
                items: [],
                options: {
                    dateFrom: null,
                    dateTo: null,
                    member: null,
                    milestone: null,
                    sortBy: "desc",
                },
                headers: [
                    { text: "Last Name", value: "last_name", sortable: false },
                    {
                        text: "First Name",
                        value: "first_name",
                        sortable: false,
                    },
                    {
                        text: "Middle Name",
                        value: "middle_name",
                        sortable: false,
                    },
                    { text: "Suffix", value: "suffix", sortable: false },
                    { text: "Nickname", value: "nickname", sortable: false },
                    {
                        text: "Membership",
                        value: "user_information.membership.name",
                        sortable: false,
                    },
                    // {
                    //     text: "Milestones",
                    //     value: "milestones",
                    //     sortable: false,
                    // },
                    {
                        text: "Created On",
                        value: "created_at",
                        sortable: false,
                    },
                    {
                        text: "",
                        value: "actions",
                        sortable: false,
                        width: "130px",
                    },
                ],
                search: null,
                loading: false,
                pagination: {
                    page: 1,
                    perPage: 10,
                    total: 0,
                    itemsPerPageOptions: [5, 10, 15, 20],
                    lastPage: 1,
                    currentPage: 1,
                },
            },
            isDateRangeDialogOpen: false,
            dateRangeDescription: null,
            userForm: { show: false, action: "", data: null },
            userInformationDialog: { show: false, data: null },
            userMilestoneDialog: { show: false, data: null },
            selectedMinistryPage: "/ministry/users",
            profileItems: [
                {
                    text: "Users",
                    value: "/ministry/users",
                },
                {
                    text: "Groups",
                    value: "/ministry/groups",
                },
                {
                    text: "Requests",
                    value: "/ministry/requests",
                },
                {
                    text: "Announcements",
                    value: "/ministry/announcements",
                },
            ],
            show: false,
        };
    },

    computed: {
        sorts() {
            return this.$store.state.selection.sorts;
        },

        perPageItems() {
            return this.$store.state.selection.perPageItems;
        },

        memberships() {
            return this.$store.state.profile.memberships;
        },

        milestones() {
            return this.$store.state.profile.milestones;
        },
    },

    watch: {
        ["table.options"]: {
            handler() {
                this.table.pagination.page = 1;
                this.getUsers();
            },
            deep: true,
        },
    },

    methods: {
        async getUsers() {
            console.log("TABLE", this.table)
            this.table.items = []
            const payload = {
                ...this.table.pagination,
                ...this.table.options,
            };
            console.log("PAYLOAD", payload)
            this.table.loading = true;
            const { success, data } = await this.$store.dispatch(
                GET_USERS,
                payload
            );
            if (success) {
                this.table.pagination.currentPage = data.meta.current_page || 0;
                this.table.pagination.total = data.meta.total || 0;
                this.table.pagination.lastPage = data.meta.last_page || 0;
                this.table.items = [...data.data];
                this.table.loading = false;
                return;
            }
            this.table.loading = false;
        },

        async onPaginationChange(page) {
            this.table.pagination.page = page;
            await this.getUsers();
        },

        openUserFormDialog(action) {
            switch (action) {
                case "add":
                    this.userForm = { show: true, action, data: null };
                    break;
                case "edit":
                    this.userForm = {
                        show: true,
                        action,
                        data: Object.assign(
                            {},
                            this.userInformationDialog.data
                        ),
                    };
                    this.userInformationDialog.show = false;
                    break;
                case "delete":
                    break;
                default:
                    this.userForm = { show: true, action, data: null };
                    break;
            }
        },

        clearDateRanges() {
            this.table.options.dateFrom = null;
            this.table.options.dateTo = null;
        },

        onOpenUpdateUserForm({ data }) {
            this.userInformationDialog = Object.assign(
                {},
                {
                    show: false,
                    data: Object.assign({}, data),
                }
            );
            this.openUserFormDialog("edit");
        },

        formatDate(timestamp) {
            return dateUtility.toSimpleDate(timestamp);
        },

        async openMinistryInformationDialog(data) {
            this.userInformationDialog = Object.assign(
                {},
                {
                    data: Object.assign({}, data),
                    show: true,
                }
            );
        },

        async openMilestoneDialog(data) {
            this.userMilestoneDialog = Object.assign(
                {},
                {
                    data: Object.assign({}, data),
                    show: true,
                }
            );
        },

        changeView(to) {
            this.$router.push(to);
        },

        countMilestones(milestones) {
            return (
                milestones
                    .map((milestone) => milestone.status > 0)
                    .filter((item) => item).length || 0
            );
        },

        async initiate() {
            this.selectedMinistryPage = this.$route.path;
            await this.getUsers();
            await this.$store.dispatch(GET_PROFILE_MEMBERSHIPS);
            await this.$store.dispatch(GET_PROFILE_MILESTONES);
            await this.$store.dispatch(GET_PROFILE_DESIGNATIONS);
            await this.$store.dispatch(GET_PROFILE_ROLES);
            await this.$store.dispatch(GET_PROFILE_RELATIONSHIPS);
        },
    },

    created() {
        this.initiate();
    },
};
</script>

<style lang="scss" scoped>
.v-select::v-deep .v-input__control {
    max-height: 20px !important;
}
.ministry-pages::v-deep .v-select__selection--comma {
    color: #78756a;
}
</style>
